import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const index =({national, refugee}) => {

  return (
    <div class="col-lg-3 col-md-12 col-6 mb-4 stat">
        <h6>Project Reach by Nationality</h6>
        <Doughnut data={
          {
            labels: ['National', 'Refugee'],
            datasets: [
              {
                label: '% Reach',
                data: [national, refugee],
                backgroundColor: [
                  '#f60029',
                  '#f24a61',
                ],
              },
            ],
          }
        } />
    </div>
  )
}

export default index
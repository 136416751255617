import React from 'react'

const Index = ({title, dataKeys, dataValues, selected, handleChange, name}) => {
    
  return (
    <>
    <label class="input-group-text" for="inputGroupSelect01">{ title}</label>
    <select class="form-select" id="inputGroupSelect01" 
            onChange={(e)=>handleChange(e)}
            name={name}
    >
        <option  >Choose...</option>
            { 
            
            dataKeys.map((item, i)=>{
               let sel =""
              if(item == selected){
                sel = "selected"
              }
               return (<option value={item} selected={sel} >{ dataValues[i] }</option>)
              
              })
            }
    </select></>
  )
}

export default Index
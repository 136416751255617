import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../templates/layout'
import Spinner from '../../components/spinner/Spinner'
import Alert from '../../components/alert/Alert';

const Upload = () => {
  const token = localStorage.getItem("token");
  const [filesData, setFilesData] = useState({})
  const [loading, setLoading] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  
  const handleChange =(e)=>{
    setFilesData({...filesData, [e.target.name]: e.target.files[0]})
  }
   
  const handleAlert =()=>{
    setTimeout(() => setAlertMessage(''), 2000);
    //setTimeout(() => window.location ='/aeplearners/new',2200)
  }

  const handleSubmit =(e)=>{

    e.preventDefault();
    setLoading(true)
     axios.post('https://dolphmax.com/?p=yitraining/upload&token='+token, filesData,{
        headers:{
            'Content-Type':'multipart/form-data'
        }
     })
     .then(res=>{
         console.log(res);
         setAlertMessage("data uploaded Successfully");
         handleAlert();
         setLoading(false);
         setFilesData({})
     })
     .catch(error=>{
       console.log(error);
       setLoading(false)
     })
  }

  return (
    <Layout>
    <div class="table-responsive text-nowrap">
      <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span><span class="text-muted fw-light">Youth in training /</span> Upload</h4>
      { loading? <Spinner />: <form> 
      <Alert message={alertMessage} />
       <article>
        <label class="form-label" for="inputGroupSelect01">File Upload</label>
        <div class="input-group">
            <input type="file"       
                   onChange={(e)=>handleChange(e)}
                   class="form-control" 
                   id="inputGroupFile04" 
                   name="file"
                   aria-describedby="inputGroupFileAddon04" 
                   aria-label="Upload" 
            />
        </div>
        <button class="btn btn-outline-primary" 
                style={{"marginTop":"20px"}} 
                onClick={handleSubmit}
                type="button" 
                id="inputGroupFileAddon04">
         Upload</button>
       </article>
       </form>
}
    </div>
</Layout>
  )
}

export default Upload
import React from 'react'

const index = ({total, current, totalPage}) => {
  return (<>
    <br/>
    <button style={{"paddingLeft":"0"}} type="button" class="btn">Displaying { (total > 10)? current * 10: total } of { total } Results</button>
    <button type="button" style={{"marginLeft":"60%"}} class="btn">Page { current} of { totalPage } Page(s)</button>
</>
  )
}

export default index
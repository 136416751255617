import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const index = ({yes, no}) => {

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
          },
        },
      };
      
      const labels = ['disability'];
      
      const data = {
        labels,
        datasets: [
          {
            label: 'Yes',
            data: [yes],
            backgroundColor: '#f60029',
          },
          {
            label: 'No',
            data: [no],
            backgroundColor: '#f24a61',
          },
        ],
      };
  return (
    <div class="col-lg-5 col-md-12 col-6 mb-4 stat">
        <h6>Project Reach by Disability</h6>
        <Bar options={options} data={data} />
    </div>
  )
}

export default index
import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../templates/layout'
import Spinner from '../../components/spinner/Spinner';
import Pagination from '../../components/pagination'
import Alert from '../../components/alert/Alert';
import Select from '../../components/select';
import NavDesc from '../../components/navDescip'

const Yitraining = () => {

  const token = localStorage.getItem("token");
  const [dash, setDash] = useState([])
  const [loading, setLoading] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [current, setCurrent] =useState(1)
  const [totalPage, setTotalPage] =useState(1)
  const [total, setTotal] =useState(0)

  const [district, setDistrict] = useState([])
  const [funding, setFunding] = useState([])
  const [formData, setFormData] =useState({
    district:"",
    funding_project:"",
    status:-1,
  })
  const GetDash =(e) =>{
      setLoading(true)
        axios.get('https://dolphmax.com/?p=dashboard/find_all_dash/3&g='+current+
        '&district='+formData.district+'&funding_project='+formData.funding_project+
      '&status='+formData.status+'&token='+token)
      .then(res=>{
          console.log("from api:",res.data);
          setDash(res.data.data)
          setCurrent(res.data.pagination.current)
          setTotal(res.data.pagination.total)
          setTotalPage(res.data.pagination.totalPage)
          setLoading(false);
      })
      .catch(error=>{
        console.log(error);
        setLoading(false);
      })
  }

  const handleDelete =(id) =>{
    setLoading(true)
      axios.get('https://dolphmax.com/?p=dashboard/delete/'+id+'&token='+token)
    .then(res=>{
        console.log(res);
        GetDash();
        setLoading(false)
        setAlertMessage("Dash deleted successfully");
        handleAlert();
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  
  const GetDistrict =(e) =>{
    setLoading(true)
      axios.get('https://dolphmax.com/?p=district/find_all&token='+token)
    .then(res=>{
        //console.log("from disability",res.data);
        setDistrict(res.data.data)
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
    })
  }

  const GetFunding =(e) =>{
    setLoading(true)
      axios.get('https://dolphmax.com/?p=funding_project/find_all&token='+token)
    .then(res=>{
        //console.log("from disability",res.data);
        setFunding(res.data.data)
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
    })
  }
  
  const handleNextPage =()=>{
    setCurrent(current + 1);
 }

 const handlePrevPage =()=>{
   setCurrent(current - 1);
 }

 const handleFirstPage =()=>{
   setCurrent(1);
 }

 const handleLastPage =()=>{
   setCurrent(total);
 }

 const handlePage =(id)=>{
   setCurrent(id);
 }

  const handleAlert =()=>{
    setTimeout(() => setAlertMessage(''), 2000);
  }

  const handleFilter =(e)=>{
    GetDash();
  }
  const handleChange =(e)=>{
    setFormData({...formData,[e.target.name]:e.target.value})
    console.log("form",formData)
  }
  useEffect(()=>{
    document.title ="Dolph Max | Dash"
      GetDash();
      GetDistrict();
      GetFunding();
  },[current])

  return (
    <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Youth in Training Institutions</h4>
    <Link to={'/yitraining/new'}><button type="button" class="btn btn-outline-dark">New Dash</button></Link>
    <Link to={'/yitraining/upload'}><button type="button" style={{"marginLeft":"79%"}} class="btn btn-outline-dark">Upload</button></Link>   
    <NavDesc total={total} totalPage={totalPage} current={current}/>
       <section className='row distribution'>
       <div className='col-md-2'>
                <Select title="District" 
                        dataKeys={district.map((item)=>{ return item.id })} 
                        dataValues={district.map((item)=>{ return item.name })}
                        handleChange={handleChange}
                        name='district'
                        selected={formData.district}
                />
            </div> 
            <div className='col-md-2'>
                <Select title="Funding Project" 
                        dataKeys={funding.map((item)=>{ return item.id })} 
                        dataValues={funding.map((item)=>{ return item.name })}
                        handleChange={handleChange}
                        name='funding_project'
                        selected={formData.funding_project}
            />   
            </div> 
            <div className='col-md-2'>
                <Select title="Status" 
                        dataKeys={["1","0"]} 
                        dataValues={["Active","Inactive"]}
                        handleChange={handleChange}
                        name='status'
                />
            </div> 
            <div className='col-md-4'>
              <button className='btn btn-dark'
               onClick={handleFilter}
              >Go</button>
            </div>
            <div className='col-md-2'>
                <Select title="Export Data" 
                        dataKeys={["pdf","csv"]} 
                        dataValues={["PDF","CSV"]}
                        handleChange={handleChange}
                        name='export'
                />
            </div> 
          </section>     
    <Alert message={alertMessage} />
    { loading?  <Spinner /> : 
           <table class="table card-table">
             <thead>
               <tr>
                 <th>ID</th>
                 <th>Institution</th>
                 <th>District</th>
                 <th>Program</th>
                 <th>Status</th>
                 <th>Actions</th>
               </tr>
             </thead>
             <tbody class="table-border-bottom-0">
             { dash.map((item)=>{  
                 return <tr>
                   <td>{ item.id}</td>
                   <td><Link to={"/yitraining/"+item.id}>{ item.name}</Link></td>
                   <td>{ item.district}</td>
                   <td>{ item.program}</td>
                   <td>{ (item.status == 1)? <span class="badge bg-label-success me-1">Active</span>:
                   <span class="badge bg-label-danger me-1"> Inactive</span> }
                   </td>
                   <td>
                   <div class="dropdown">
                <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                  <i class="bx bx-dots-vertical-rounded"></i>
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href={"/yitraining/edit/"+item.id}
                    ><i class="bx bx-edit-alt me-1"></i> Edit</a
                  >
                  <a class="dropdown-item" href="javascript:void(0);"
                      onClick={()=>handleDelete(item.id)}
                    ><i class="bx bx-trash me-1"></i> Delete</a
                  >
                </div>
              </div>
                   </td>
                 </tr>
               }) }
               </tbody>
               </table>
             }
             <Pagination 
             current={current} 
             total={totalPage} 
             handleNextPage={handleNextPage} 
             handlePrevPage={handlePrevPage} 
             handleFirstPage={handleFirstPage} 
             handleLastPage={handleLastPage} 
             handlePage={handlePage} 
         />
     </div>
     </Layout>
  )
}

export default Yitraining
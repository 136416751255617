import React from 'react'
import {Link, useHistory ,useLocation } from 'react-router-dom';

import Logo from '../../assets/img/logo.jpg';

const index = () => {

  return (
    <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
    <div className="app-brand demo">
      <Link to="/" className="app-brand-link">
        <span className="app-brand-logo demo">
        <img src={Logo} alt='' width='15%' style={{'margin':'10px 30px'}} />
           <br/>
        </span>
      </Link>

      <a href="javascript:void(0);" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
        <i className="bx bx-chevron-left bx-sm align-middle"></i>
      </a>
    </div>
    <div className="menu-inner-shadow"></div>
    <ul className="menu-inner py-1">
      <li className="menu-item">
        <Link to="/dashboard" className="menu-link">
          <i className="menu-icon tf-icons bx bx-home-circle"></i>
          <div data-i18n="Analytics">Dashboard</div>
        </Link>
      </li>
      <li className="menu-item ">
        <Link to="/aeplearners" className="menu-link">
          <i className="menu-icon tf-icons bx bx-copy"></i>
          <div data-i18n="Analytics">AEP Learners</div>
        </Link>
      </li>
      <li className="menu-item ">
        <Link to="/yitraining" className="menu-link">
          <i className="menu-icon tf-icons bx bx-copy"></i>
          <div data-i18n="Analytics">Youth in Training</div>
        </Link>
      </li>
      <li className="menu-item ">
        <Link to="/yiemployment" className="menu-link">
          <i className="menu-icon tf-icons bx bx-copy"></i>
          <div data-i18n="Analytics">Youth in Works</div>
        </Link>
      </li>
      <li className="menu-item ">
        <Link to="/distributions" className="menu-link">
          <i className="menu-icon tf-icons bx bx-copy"></i>
          <div data-i18n="Analytics">Distributions</div>
        </Link>
      </li>
      <li className="menu-item ">
        <Link to="/tvet" className="menu-link">
          <i className="menu-icon tf-icons bx bx-copy"></i>
          <div data-i18n="Analytics">TVET</div>
        </Link>
      </li>
      <li className="menu-item ">
        <Link to="/feedback" className="menu-link">
          <i className="menu-icon tf-icons bx bx-lock-open-alt"></i>
          <div data-i18n="Analytics">Feedback</div>
        </Link>
      </li>
      <li className="menu-item ">
        <Link to="/staff" className="menu-link">
          <i className="menu-icon tf-icons bx bx-lock-open-alt"></i>
          <div data-i18n="Analytics">Staff</div>
        </Link>
      </li>
      <li className="menu-item ">
        <Link to="/settings" className="menu-link">
          <i className="menu-icon tf-icons bx bx-lock-open-alt"></i>
          <div data-i18n="Analytics">Settings</div>
        </Link>
      </li>
    </ul>
  </aside>
  )
}

export default index
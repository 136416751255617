import React from 'react'

const Input = ({title, value, placeholder, handleChange, name, type='text'}) => {
  return (
    <article >
    <label htmlFor="title" class="form-label">{ title }</label>
        <input
          type={type}
          class="form-control"
          id="title"
          name={name}
          placeholder={ placeholder }
          value={value}
          onChange={(e)=>handleChange(e)}

        />
      </article>
  )
}

export default Input
import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../templates/layout'
import Spinner from '../../components/spinner/Spinner';
import Pagination from '../../components/pagination'
import Alert from '../../components/alert/Alert';

const Index = () => {
  const token = localStorage.getItem("token");
  const [feedback, setFeedback] = useState([])
  const [loading, setLoading] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [current, setCurrent] =useState(1)
  const [total, setTotal] =useState(1)
  
  const handleAlert =()=>{
    setTimeout(() => setAlertMessage(''), 2000);
  }
  const GetFeedback =(e) =>{
      setLoading(true)
        axios.get('https://dolphmax.com/?p=feedback/find_all&g='+current+'&token='+token)
      .then(res=>{
          console.log("from api:",res.data);
          setFeedback(res.data.data);
          setCurrent(res.data.pagination.current)
          setTotal(res.data.pagination.total)
          setLoading(false);
      })
      .catch(error=>{
        console.log(error);
        setLoading(false);
      })
  }

  const handleDelete =(id) =>{
    setLoading(true)
      axios.get('https://dolphmax.com/?p=dashboard/delete/'+id+'&token='+token)
    .then(res=>{
        console.log(res);
        GetFeedback();
        setLoading(false)
        setAlertMessage("Feedback deleted successfully");
        handleAlert();
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const handleNextPage =()=>{
    setCurrent(current + 1);
 }

 const handlePrevPage =()=>{
   setCurrent(current - 1);
 }

 const handleFirstPage =()=>{
   setCurrent(1);
 }

 const handleLastPage =()=>{
   setCurrent(total);
 }

 const handlePage =(id)=>{
   setCurrent(id);
 }

  useEffect(()=>{
    document.title ="Dolph Max | Feedback"
      GetFeedback();
  },[])
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Feedback</h4>
    <Link to={'/feedback/upload'}><button type="button" class="btn btn-outline-dark">Upload</button></Link>   
    <Alert message={alertMessage} />

    { loading?  <Spinner /> : 
            <table class="table card-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Client Name</th>
                <th>Case Status</th>
                <th>Mechanism</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
            { feedback.map((item)=>{  
                return <tr>
                  <td>{ item.id}</td>
                  <td><Link to={"/feedback/"+item.id}>{ item.feedback_category}</Link></td>
                  <td>{ item.client_name||'Anonymous'}</td>
                  <td>{ item.case_status}</td>
                  <td>{ item.mechanism}</td>
                  <td>
                  <div class="dropdown">
               <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                 <i class="bx bx-dots-vertical-rounded"></i>
               </button>
               <div class="dropdown-menu">
                 <a class="dropdown-item" href={"/feedback/edit/"+item.id}
                   ><i class="bx bx-edit-alt me-1"></i> Edit</a
                 >
                 <a class="dropdown-item" href="javascript:void(0);"
                   onClick={()=>handleDelete(item.id)}
                   ><i class="bx bx-trash me-1"></i> Delete</a
                 >
               </div>
             </div>
                  </td>
                </tr>
              }) }
              </tbody>
               </table>
             }
             <Pagination 
             current={current} 
             total={total} 
             handleNextPage={handleNextPage} 
             handlePrevPage={handlePrevPage} 
             handleFirstPage={handleFirstPage} 
             handleLastPage={handleLastPage} 
             handlePage={handlePage} 
         />
     </div>
</Layout>
  )
}

export default Index
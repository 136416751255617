import React, {useState, useEffect} from 'react'
import axios from 'axios'
import "../../../assets/page-auth.css"
import Logo from '../../../assets/img/logo.jpg'
import Spinner from '../../../components/spinner/Spinner';
import './index.css'

const Index = () => {

  const [formData, setFormData] = useState({
    email:"",
    password:""
  })
  const [alert, setAlert] = useState('')
  const [loading, setLoading] = useState(false)
  const [passtype, setPassType] =useState('password')

  const handleLogin =(e) =>{
      e.preventDefault();
      console.log(formData)
      setLoading(true)
      axios.post('https://dolphmax.com/?p=auth/login', formData)
      .then(res=>{
        console.log(res);
        setLoading(false)
        if(res.data.token){
          localStorage.setItem("token", res.data.token);
          window.location="/dashboard";
        }else{
          setLoading(false)
          console.log('error')
          setAlert("User not found")
        }
      })
      .catch(error=>{
        console.log(error);
        setLoading(false)
        setAlert("An error occured")
      })
  }

  useEffect(()=>{
    document.title = 'Dolph Max | Admin';
  },[])

  return (
    <div className="container-xxl">
      <div className="authentication-wrapper authentication-basic container-p-y">
        <div className="authentication-inner">
       
          <section className="card">
            <div className="card-body">
              <div className="app-brand justify-content-center">
                <a href="#" className="app-brand-link gap-2">
                  <span className="app-brand-logo demo">
                    <img src={Logo} alt='' width='23%' style={{"marginLeft":"17%"}} />
                  </span>
                </a>
              </div>
              <h4 style={{"color":"#f60129","textAlign":"center"}}>ADMIN</h4>
              <p className="mb-4">Please sign-in to your account </p>
              {alert !=""?<div class="alert alert-danger" role="alert">{alert}</div>:"" }
              <form id="formAuthentication" className="mb-3" onSubmit={handleLogin}>
                <div class="mb-3">
                  <label htmlFor="email" class="form-label">Email</label>
                  <input
                    type="text"
                    class="form-control"
                    id="email"
                    name="email-username"
                    placeholder="Enter your email"
                    autoFocus
                    value={formData.email}
                    onChange={(e)=> setFormData({...formData, email: e.target.value})}
                  />
                </div>
                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" htmlFor="password">Password</label>
                    <a href="/forgot_pass">
                      <small>Forgot Password?</small>
                    </a>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      type={passtype}
                      id="password"
                      className="form-control"
                      name="password"
                      value={formData.password}
                      placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                      aria-describedby="password"
                      onChange={(e)=> setFormData({...formData, password: e.target.value})}
                    />
                    <span className="input-group-text cursor-pointer"
                      onClick={(e)=>{
                        if(passtype =='password'){
                           setPassType('text')
                        }else{
                           setPassType('password')
                        }
                      }}
                    ><i className="bx bx-hide"></i></span>
                  </div>
                </div>
                <div className="mb-3">
                  <button className="btn btn-primary loading-btn d-grid w-100" type="submit">
                  { loading?  <Spinner color="#fff" size='small' /> : ""} <span>Sign in</span> </button>
                </div>
              </form>
             
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Index
import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../templates/layout'
import Spinner from '../../components/spinner/Spinner';
import Alert from '../../components/alert/Alert';
import Pagination from '../../components/pagination'
const Users = () => {
  const token = localStorage.getItem("token");
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [current, setCurrent] =useState(1)
  const [total, setTotal] =useState(1)
  
  const GetUsers =(e) =>{
      setLoading(true)
        axios.get('https://dolphmax.com/?p=auth/find_all&g='+current+'&token='+token)
      .then(res=>{
          console.log("from api:",res.data);
          setUsers(res.data.data)
          setCurrent(res.data.pagination.current)
          setTotal(res.data.pagination.total)
          setLoading(false);
      })
      .catch(error=>{
        console.log(error);
        setLoading(false);
      })
  }

  const handleNextPage =()=>{
    setCurrent(current + 1);
 }

 const handlePrevPage =()=>{
   setCurrent(current - 1);
 }

 const handleFirstPage =()=>{
   setCurrent(1);
 }

 const handleLastPage =()=>{
   setCurrent(total);
 }

 const handlePage =(id)=>{
   setCurrent(id);
 }
  const handleAlert =()=>{
    setTimeout(() => setAlertMessage(''), 2000);
  }

  const handleDelete =(id) =>{
    setLoading(true)
      axios.get('https://dolphmax.com/?p=auth/delete/'+id+'&token='+token)
    .then(res=>{
        console.log(res);
        GetUsers();
        setLoading(false)
        setAlertMessage("Staff deleted successfully");
        handleAlert();
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  useEffect(()=>{
    document.title ="Dolph Max | Dash"
      GetUsers();
  },[current])
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Staff</h4>
    <Link to={'/staff/new'}><button type="button" class="btn btn-outline-dark">New Staff</button></Link>
    <Alert message={alertMessage} />
    { loading?  <Spinner /> : 
           <table class="table card-table">
             <thead>
               <tr>
                 <th>ID</th>
                 <th>Fullname</th>
                 <th>Email</th>
                 <th>Role</th>
                 <th>Status</th>
                 <th>Actions</th>
               </tr>
             </thead>
             <tbody class="table-border-bottom-0">
             { users.map((item)=>{  
                 return <tr>
                   <td>{ item.id}</td>
                   <td><Link to={"/staff/"+item.id}>{ item.fullname}</Link></td>
                   <td>{ item.email}</td>
                   <td>{ item.role}</td>
                   <td>{ (item.active == 1)? <span class="badge bg-label-success me-1">active</span>:
                   <span class="badge bg-label-success me-1"> inactive</span> }</td>
                   <td>
                   <div class="dropdown">
                <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                  <i class="bx bx-dots-vertical-rounded"></i>
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href={"/staff/edit/"+item.id}
                    ><i class="bx bx-edit-alt me-1"></i> Edit</a
                  >
                  <a class="dropdown-item" href="javascript:void(0);"
                      onClick={()=>handleDelete(item.id)}
                    ><i class="bx bx-trash me-1"></i> Delete</a
                  >
                </div>
              </div>
                   </td>
                 </tr>
               }) }
               </tbody>
               </table>
             }
             <Pagination 
             current={current} 
             total={total} 
             handleNextPage={handleNextPage} 
             handlePrevPage={handlePrevPage} 
             handleFirstPage={handleFirstPage} 
             handleLastPage={handleLastPage} 
             handlePage={handlePage} 
         />
     </div>
</Layout>
  )
}

export default Users
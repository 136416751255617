import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Layout from '../../../templates/layout';
import Input from '../../../components/input/Input';
import Select from '../../../components/select/index';
import Alert from '../../../components/alert/Alert';
import Spinner from '../../../components/spinner/Spinner'
import {useParams} from "react-router-dom";

const Index = () => {
  const token = localStorage.getItem("token");
   const [loading, setLoading] = useState(false)
   const [alertMessage, setAlertMessage] = useState("")
   const [district, setDistrict] = useState([])
   const [funding, setFunding] = useState([])
   const {id} = useParams()

   const [formData, setFormData] = useState({
      name:"",
      district_id:0,
      program_id:1,
      funding_project_id:0,
      status:-1
   })

   const handleChange =(e)=>{
      setFormData({...formData,[e.target.name]:e.target.value})
      console.log("form",formData)
    }

    const handleAlert =()=>{
      setTimeout(() => setAlertMessage(''), 2000);
      setTimeout(() => window.location ='/aeplearners',2200)
    }

    const GetDistrict =(e) =>{
      setLoading(true)
        axios.get('https://dolphmax.com/?p=district/find_all&token='+token)
      .then(res=>{
          //console.log("from disability",res.data);
          setDistrict(res.data.data)
          setLoading(false)
      })
      .catch(error=>{
        console.log(error);
      })
    }

    const GetFunding =(e) =>{
      setLoading(true)
        axios.get('https://dolphmax.com/?p=funding_project/find_all&token='+token)
      .then(res=>{
          //console.log("from disability",res.data);
          setFunding(res.data.data)
          setLoading(false)
      })
      .catch(error=>{
        console.log(error);
      })
    }

      const GetDash =(e) =>{
        setLoading(true)
          axios.get('https://dolphmax.com/?p=dashboard/find_dash/'+id+'&token='+token)
        .then(res=>{
            console.log("find dash",res.data);
            setFormData(res.data)
            setLoading(false)
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }

    const handleSubmit = (e)=>{
      e.preventDefault();
      setLoading(true)
       axios.post('https://dolphmax.com/?p=dashboard/update/'+id+'&token='+token, formData)
       .then(res=>{
           console.log(res);
           setAlertMessage("Dash updated Successfully");
           handleAlert();
           setLoading(false);
       })
       .catch(error=>{
         console.log(error);
         setLoading(false)
       })
    }

    useEffect(()=>{
      document.title = 'Dolph max | AEP Edit';
        GetDistrict();
        GetFunding();
        GetDash();
    },[])

  return (
   <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /AEP Dashboard/</span>Edit </h4>
    </div>
   <section class="col-xll">
   <div class="card mb-4">
     <div class="card-header d-flex justify-content-between align-items-center">
       <h5 class="mb-0">Edit Dashboard </h5>
       <small class="text-muted float-end">Update an AEP Dashboard</small>
     </div>
     <div class="card-body">
     <Alert message={alertMessage} />
       { loading? <Spinner />: <form method='POST'> 
       <section className='row' >
          <div className='col-md-6'>
              <Input title={'Dash name'} 
                     placeholder={'This is the dashboard name'} 
                     handleChange={handleChange}
                     name={'name'}
                     value={formData.name}
             />
          </div>

          <div className='col-md-6' style={{"display":"flex","height":"40px","marginTop":"30px"}}>
          <Select title="District" 
               dataKeys={district.map((item)=>{ return item.id })} 
               dataValues={district.map((item)=>{ return item.name })}
               handleChange={handleChange}
               name='district_id'
               selected={formData.district_id}
         />   
          </div>
       </section>
       <section className='row' >
          <div className='col-md-6' style={{"display":"flex","height":"40px","marginTop":"30px"}}>
          <Select title="Funding Project" 
               dataKeys={funding.map((item)=>{ return item.id })} 
               dataValues={funding.map((item)=>{ return item.name })}
               handleChange={handleChange}
               name='funding_project_id'
               selected={formData.funding_project_id}
         />   
          </div>
            
          <div className='col-md-6' style={{"display":"flex","height":"40px","marginTop":"30px"}}>

          <Select title="Status" 
               dataKeys={[1,0]}
               dataValues={["active","inactive"]}
               handleChange={handleChange}
               name='status'
               selected={formData.status}
         />   
          </div>
        </section>
        <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handleSubmit}>Submit</button>
           </div>
        </form>
}
        </div>
        </div>
        </section>
      </Layout>
  )
}

export default Index
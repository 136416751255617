import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Layout from '../../../templates/layout'
import Spinner from '../../../components/spinner/Spinner'
import {useParams} from "react-router-dom";

const Index = () => {
  const token = localStorage.getItem("token");
  const [dashData, setDashData] = useState({})
  const [loading, setLoading] = useState(false)
  const {id} = useParams()

  const GetDash =(e) =>{
    setLoading(true)
      axios.get('https://dolphmax.com/?p=dashboard/find_dash/'+id+'&token='+token)
    .then(res=>{
        console.log("find dash",res.data);
        setDashData(res.data)
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  useEffect(()=>{
    document.title ="Dashboard | YIW"
    GetDash();
  },[])
  return (
    <Layout>
       <div class="table-responsive text-nowrap">
           <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard / Youth in Works</span> / View</h4>
        </div>
        <article className={'step active'}>
            <h5>Dash</h5>
            <table class="table card-table">
                    <tbody class="table-border-bottom-0">
                      <tr>
                        <th>Dash Name:</th>
                        <th>{dashData.name}</th>
                      </tr>
                      <tr>
                        <th>District:</th>
                        <th>{dashData.district}</th>
                      </tr>
                      <tr>
                        <th>Project:</th>
                        <th>{dashData.program}</th>
                      </tr>
                      <tr>
                        <th>Funding Organisation:</th>
                        <th>{dashData.funding_project}</th>
                      </tr>
                      <tr>
                        <th>Status:</th>
                        <th>{dashData.status}</th>
                      </tr>
                      </tbody>
               </table>
        </article>

    </Layout>
  )
}

export default Index
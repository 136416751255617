import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Layout from '../../templates/layout'

const Reports = () => {
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
        <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span>Reports</h4>
    </div>
  </Layout>
  )
}

export default Reports
import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './containers/auth/login/index';
import Forgotpass from './containers/auth/forgotPass/index'
import Dashboard from './containers/home/Home'
import Users from './containers/users/Users';
import UsersView from './containers/users/view'
import UsersItem from './containers/users/edit'
import UsersNew from './containers/users/new'
import Reports from './containers/reports/Reports';
import Statistics from './containers/statistics/Statistics';

import Aeplearners from './containers/aeplearners/aeplearners';
import AeplearnersUpload from './containers/aeplearners/upload';
import AeplearnersNew from './containers/aeplearners/new/index';
import AeplearnersItem from './containers/aeplearners/edit';
import AeplearnersView from './containers/aeplearners/view';

import Yiemployment from './containers/yiemployment/yiemployment';
import YiemploymentUpload from './containers/yiemployment/upload';
import YiemploymentView from './containers/yiemployment/view';
import YiemploymentNew from './containers/yiemployment/new';
import YiemploymentItem from './containers/yiemployment/edit';

import YitrainingUpload from './containers/yitraining/upload';
import Yitraining from './containers/yitraining/Yitraining';
import YitrainingNew from './containers/yitraining/new';
import YitrainingItem from './containers/yitraining/edit';
import YitrainingView from './containers/yitraining/view';

import TvtUpload from './containers/tvt/upload';
import Tvt from './containers/tvt/';
import TvtNew from './containers/tvt/new';
import TvtItem from './containers/tvt/edit';
import TvtView from './containers/tvt/view';

import DistributionUpload from './containers/distribution/upload';
import Distribution from './containers/distribution';
import DistributionNew from './containers/distribution/new';
import DistributionItem from './containers/distribution/edit';
import DistributionView from './containers/distribution/view';

import FeedbackUpload from './containers/feedback/upload';
import Feedback from './containers/feedback';
import FeedbackNew from './containers/feedback/new';
import FeedbackView from './containers/feedback/view';
import Settings from './containers/settings'

const App = () => {

  return (
   
    <BrowserRouter>
    <Routes> 
        <Route path="/" exact element={<Login />} />
        <Route path="/forgot_pass" element={<Forgotpass />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/staff" element={<Users />} />
        <Route path="/staff/:id" element={<UsersView />} />
        <Route path="/staff/edit/:id" element={<UsersItem />} />
        <Route path="/staff/new" element={<UsersNew />} />

        <Route path="/reports" element={<Reports />} />
        <Route path="/statistics" element={<Statistics />} /> 

        <Route path="/aeplearners" element={<Aeplearners />} /> 
        <Route path="/aeplearners/upload" element={<AeplearnersUpload />} />
        <Route path="/aeplearners/edit/:id" element={<AeplearnersItem />} />
        <Route path="/aeplearners/new" element={<AeplearnersNew />} />
        <Route path="/aeplearners/:id" element={<AeplearnersView />} />

        <Route path="/yiemployment" element={<Yiemployment />} /> 
        <Route path="/yiemployment/upload" element={<YiemploymentUpload />} /> 
        <Route path="/yiemployment/:id" element={<YiemploymentView />} />  
        <Route path="/yiemployment/edit/:id" element={<YiemploymentItem />} />   
        <Route path="/yiemployment/new" element={<YiemploymentNew />} />      

        <Route path="/yitraining" element={<Yitraining />} />
        <Route path="/yitraining/upload" element={<YitrainingUpload />} />  
        <Route path="/yitraining/new" element={<YitrainingNew />} />
        <Route path="/yitraining/:id" element={<YitrainingView />} />
        <Route path="/yitraining/edit/:id" element={<YitrainingItem />} />

        <Route path="/tvet" element={<Tvt />} />
        <Route path="/tvet/upload" element={<TvtUpload />} />  
        <Route path="/tvet/new" element={<TvtNew />} />
        <Route path="/tvet/:id" element={<TvtView />} />
        <Route path="/tvet/edit/:id" element={<TvtItem />} />

        <Route path="/distributions" element={<Distribution />} />
        <Route path="/distributions/upload" element={<DistributionUpload />} />  
        <Route path="/distributions/new" element={<DistributionNew />} />
        <Route path="/distributions/:id" element={<DistributionView />} />
        <Route path="/distributions/edit/:id" element={<DistributionItem />} />

        <Route path="/feedback" element={<Feedback />} />
        <Route path="/feedback/upload" element={<FeedbackUpload />} />  
        <Route path="/feedback/new" element={<FeedbackNew />} />
        <Route path="/feedback/:id" element={<FeedbackView />} />
        <Route path="/settings" element={<Settings />} />
 
    </Routes>
      </BrowserRouter>
  )
}

export default App



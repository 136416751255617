import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const index = ({female, male}) => {
    console.log("data:",male, female)
  return (
    <div class="col-lg-3 col-md-12 col-6 mb-4 stat">
        <h6>Gender Disaggregation</h6>
        <Pie data={{
            labels: ['Male','Female'],
            datasets: [
              {
                label: '%',
                data: [male,female],
                backgroundColor: [
                  '#f60029',
                  '#f24a61',
                ],
               
                borderWidth: 0,
              },
            ],
        }} />
    </div>
    
  )
}

export default index
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Layout from '../../templates/layout'

const Index = () => {
  const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    
    const handleAlert =()=>{
      setTimeout(() => setAlertMessage(''), 2000);
    }

    const handleDeleteAll =(id) =>{
        setLoading(true)
          axios.get('https://dolphmax.com/?p=dashboard/clear_all&token='+token)
        .then(res=>{
            console.log(res);
            setLoading(false)
            setAlertMessage("Data deleted successfully");
            handleAlert();
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }

      const handleDeleteAEP =(id) =>{
        setLoading(true)
          axios.get('https://dolphmax.com/?p=dashboard/delete/'+id+'&token='+token)
        .then(res=>{
            console.log(res);
            setLoading(false)
            setAlertMessage("Feedback deleted successfully");
            handleAlert();
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }

      const handleDeleteYIT =(id) =>{
        setLoading(true)
          axios.get('https://dolphmax.com/?p=yitraining/clear_all&token='+token)
        .then(res=>{
            console.log(res);
            setLoading(false)
            setAlertMessage("Youth in training deleted successfully");
            handleAlert();
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }

      const handleDeleteYIW =() =>{
        setLoading(true)
          axios.get('https://dolphmax.com/?p=yiemployment/clear_all&token='+token)
        .then(res=>{
            console.log(res);
            setLoading(false)
            setAlertMessage("Youth in works deleted successfully");
            handleAlert();
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }

      const handleDeleteTVET =(id) =>{
        setLoading(true)
          axios.get('https://dolphmax.com/?p=tvet/clear_data/&token='+token)
        .then(res=>{
            console.log(res);
            setLoading(false)
            setAlertMessage("TVET deleted successfully");
            handleAlert();
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }

      const handleDeleteDistribution =(id) =>{
        setLoading(true)
          axios.get('https://dolphmax.com/?p=distriution/clear_data/&token='+token)
        .then(res=>{
            console.log(res);
            setLoading(false)
            setAlertMessage("Distribution deleted successfully");
            handleAlert();
        })
        .catch(error=>{
          console.log(error);
          setLoading(false)
        })
      }
  return (
    <Layout>
    <div class="table-responsive text-nowrap">
        <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span>Settings</h4>
    </div>
    <section class="col-xll">
                  <div class="card mb-4">
                  <div class="card-header d-flex justify-content-between align-items-center">
                      <h5 class="mb-0">Settings</h5>
                      <small class="text-muted float-end">Create settings for all dashboards</small>
                    </div>
                    <div class="card-body">
        <article>
            <div className='row'>
                <div className='col-md-6'>
                    <h5>Clear all system data</h5>
                    <button class="btn btn-outline-primary" 
                        style={{"marginTop":"20px"}} 
                       // onClick={handleDeleteAll}
                        type="button" 
                        id="inputGroupFileAddon04">
                        Clear All</button>
                </div>
                <div className='col-md-6'>
                <h5>Clear all AEP data</h5>
            <button class="btn btn-outline-primary" 
                    style={{"marginTop":"20px"}} 
                    //onClick={handleDeleteAEP}
                    type="button" 
                    id="inputGroupFileAddon04">
            Clear AEP</button>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <h5>Clear all Youth in Works</h5>
                    <button class="btn btn-outline-primary" 
                            style={{"marginTop":"20px"}} 
                           // onClick={handleDeleteYIW}
                            type="button" 
                            id="inputGroupFileAddon04">
                    Clear YIW</button>
                </div>
                <div className='col-md-6'>
            <h5>Clear all Youth in Training</h5>
            <button class="btn btn-outline-primary" 
                    style={{"marginTop":"20px"}} 
                  //  onClick={handleDeleteYIT}
                    type="button" 
                    id="inputGroupFileAddon04">
            Clear YIT</button>
            </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>

                    <h5>Clear all TVET Data</h5>
            <button class="btn btn-outline-primary" 
                    style={{"marginTop":"20px"}} 
                  //  onClick={handleDeleteTVET}
                    type="button" 
                    id="inputGroupFileAddon04">
            Clear TVET</button>
                </div>
                <div className='col-md-6'>

                    <h5>Clear all Distribution Data</h5>
            <button class="btn btn-outline-primary" 
                    style={{"marginTop":"20px"}} 
                  // onClick={handleDeleteDistribution}
                    type="button" 
                    id="inputGroupFileAddon04">
            Clear Distribution</button>
                </div>
                </div>
</article>
            
        </div>
        </div>
        </section>
    
  </Layout>
  )
}

export default Index
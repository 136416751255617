import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Layout from '../../templates/layout'
import Total from '../../components/total'
import GenderPie from '../../components/gender_pie'
import NationalBar from '../../components/nationalBar'
import DisabilityBar from '../../components/disabilityBar'

const Home = () => {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false)
  const [district, setDistrict] = useState([])
  const [nationalStat, setNationalStat] = useState({
    refugee:0,
    national:0
  })

  const [genderStats, setGenderStats] = useState({
    male:0,
    female:0
  })
  const [totalDisability, setTotalDisability] = useState({
    yes:0,
    no:0
  })

  const [formData, setFormData] = useState({
    district_id:0,
    year:0,
    quarter:""
  })

  const [total, setTotal] = useState({
    total:0,
    aep:0,
    yit:0,
    yiw:0,
    tvet:0,
    dist:0
  })
  
  const handleChange =(e)=>{
    setFormData({...formData,[e.target.name]:e.target.value})
    console.log("form",formData)
  }

  const GetTotalYouth =(e) =>{
    setLoading(true)
      axios.get("https://dolphmax.com/?p=statistics/total&d="+
      formData.district_id+"&y="+formData.year+'&q='+formData.quarter+'&token='+token)
    .then(res=>{
           
           let full = {
             total:res.data.total,
             yit: res.data.yit,
             yiw: res.data.yiw,
             aep: res.data.aep,
             tvet: res.data.tvet,
             dist:res.data.dist
           }
           console.log(full);
           setTotal(full)
           setLoading(false)
    })

    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetTotalNational =(e) =>{
    setLoading(true)
      axios.get("https://dolphmax.com/?p=statistics/total_by_nationality&d="+
      formData.district_id+"&y="+formData.year+'&q='+formData.quarter+'&token='+token)
    .then(res=>{
        console.log("national",res);
        if(res.data.length !=0){
          const refugee = parseInt(res.data.filter((item)=>item.residency_status =="Refugee")[0].total)
          const national = parseInt(res.data.filter((item)=>item.residency_status =="Host")[0].total)
          setNationalStat({
            refugee:refugee/(national+refugee)*100,
            national:national/(national+refugee)*100
          })
        }else{
          setNationalStat({
            refugee:0,
            national:0
          })
        }
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetTotalDisability =(e) =>{
    setLoading(true)
      axios.get("https://dolphmax.com/?p=statistics/total_by_disability&d="+
      formData.district_id+"&y="+formData.year+'&q='+formData.quarter+'&token='+token)
    .then(res=>{
      if(res.data.length !=0){
        const yes = parseInt(res.data.filter((item)=>item.any_disability =="Yes")[0].total)
        const no = parseInt(res.data.filter((item)=>item.any_disability =="No")[0].total)
          setTotalDisability({
            yes:yes/(no+yes)*100,
            no: no/(no+yes)*100
          })
          
        }else{
          setTotalDisability({
            yes:0,
            no:0
          })
        }
        setLoading(false)

    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }

  const GetGenderStats =(e) =>{
    setLoading(true)
      axios.get("https://dolphmax.com/?p=statistics/total_by_gender&d="+
      formData.district_id+"&y="+formData.year+'&q='+formData.quarter+'&token='+token)
    .then(res=>{
      if(res.data.length !=0){
      const male = parseInt(res.data.filter((item)=>item.gender =="Male")[0].total)
      const female = parseInt(res.data.filter((item)=>item.gender =="Female")[0].total)
         setGenderStats({
          male:male/(male+female) *100,
          female:female/(male+female)*100
        })
      }else{
        setGenderStats({
          male:0,
          female:0
        })
      }
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
      setLoading(false)
    })
  }


  const GetDistrict =(e) =>{
    setLoading(true)
      axios.get('https://dolphmax.com/?p=district/find_all&token='+token)
    .then(res=>{
        //console.log("from disability",res.data);
        setDistrict(res.data.data)
        setLoading(false)
    })
    .catch(error=>{
      console.log(error);
    })
  }

  useEffect(()=>{
    document.title ="Dolph Max | Dashboard "
    GetTotalYouth();
    GetDistrict();
    GetGenderStats();
    GetTotalNational();
    GetTotalDisability()

  },[formData])
  return (
    <Layout>
      <div class="table-responsive text-nowrap">
          <h4 class="fw-bold py-3 mb-4" style={{"display":"flex","justifyContent":"space-between"}}><span class="text-muted fw-light">Dashboard /</span>
          <div class="col-lg-6 col-md-12 col-6 mb-0" style={{"display":"flex"}}>
         
          <article class="input-group" style={{"marginBottom":"0"}} >
              <select class="form-select" id="inputGroupSelect01" 
                      onChange={(e)=>handleChange(e)}
                      name={'year'}
              >
                  <option value="0" >Yearly</option>
                  <option value="0" >All Years</option>
                  <option value="1" >Year 1</option>
                  <option value="2" >Year 2</option>
                  <option value="3" >Year 3</option>
                  <option value="4" >Year 4</option>
                  <option value="5" >Year 5</option>
              </select>
          </article>
          <article class="input-group" style={{"marginBottom":"0"}} >
              <select class="form-select" id="inputGroupSelect01" 
                      onChange={(e)=>handleChange(e)}
                      name={'quarter'}
                      disabled={(formData.year ==0)? true:false}
              >
                  <option value="0" >Quarterly</option>
                  <option value="1" >Quarter 1</option>
                  <option value="2" >Quarter 2</option>
                  <option value="3" >Quarter 3</option>
                  <option value="4" >Quarter 4</option>
                 
              </select>
          </article>
          <article class="input-group" style={{"marginBottom":"0"}} >
              <select class="form-select" id="inputGroupSelect01" 
                      onChange={(e)=>handleChange(e)}
                      name={'district_id'}
              >
                  <option  >District</option>
                  <option value="0"  >Select All</option>
                      { 
                      district.map((item, i)=>{
                        return (<option value={item.id} >{ district[i].name }</option>)
                      })
                      }
              </select>
              </article>
          </div>  
          </h4>
      </div>
      <div class="row">
          <Total name={'Total Reached'} total={total.total}  />
          <Total name={'AEP Learners'} total={total.aep}  />
          <Total name={'Youth Trained'} total={total.yit}  />
          <Total name={'Youth Employed'} total={total.yiw}  />
          <Total name={'TVET Learners'} total={total.tvet}  />
          <Total name={'Distributions'} total={total.dist}  />  
      
      </div>
      <div class="row">
          <GenderPie male={genderStats.male} female={genderStats.female} />
          <NationalBar refugee={nationalStat.refugee} national={nationalStat.national} />
          <DisabilityBar yes={totalDisability.yes} no={totalDisability.no} />
      </div>
    </Layout>
  )
}

export default Home

// reduce the red in charts

//aep levels charts // 
//marital status // bar graph
/// youth with children //learners who are parents

// youth earning above $85 monthly --- bar graph --- earnings
// status of employment  --- employment status

//no of youth per training areas ---- distribution by trade skill id
// training category  ---- distribution by placement type

//// distributions by category === chart
// distributions by items == bar graph

// feedback gender, recieved, status, category ,
// mechanism of recieving, 
import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Layout from '../../../templates/layout';
import Input from '../../../components/input/Input';
import Select from '../../../components/select/index';
import Alert from '../../../components/alert/Alert';
import Spinner from '../../../components/spinner/Spinner'

const Index = () => {
   const token = localStorage.getItem("token");
   const [loading, setLoading] = useState(false)
   const [alertMessage, setAlertMessage] = useState("")

   const [formData, setFormData] = useState({
   })

   const handleChange =(e)=>{
      setFormData({...formData,[e.target.name]:e.target.value})
      console.log("form",formData)
    }

    const handleAlert =()=>{
      setTimeout(() => setAlertMessage(''), 2000);
      setTimeout(() => window.location ='/staff/new',2200)
    }

    const handleSubmit = (e)=>{
      e.preventDefault();
      setLoading(true)
       axios.post('https://dolphmax.com/?p=auth/create/&token='+token, formData)
       .then(res=>{
           console.log(res);
           setAlertMessage("Staff created Successfully");
           handleAlert();
           setLoading(false);
       })
       .catch(error=>{
         console.log(error);
         setLoading(false)
       })
    }

    useEffect(()=>{
      document.title = 'Dolph max | Staff New';
    },[])

  return (
   <Layout>
    <div class="table-responsive text-nowrap">
    <h4 class="fw-bold py-3 mb-4"><span class="text-muted fw-light">Dashboard /</span> Staff</h4>
    </div>
   <section class="col-xll">
   <div class="card mb-4">
     <div class="card-header d-flex justify-content-between align-items-center">
       <h5 class="mb-0">New Staff Member </h5>
       <small class="text-muted float-end">Create a new member</small>
     </div>
     <div class="card-body">
     <Alert message={alertMessage} />
       { loading? <Spinner />: <form method='POST'> 
       <section className='row' >
          <div className='col-md-6'>
          <Input title={'Full name'} 
                  placeholder={'This is the members fullname'} 
                  handleChange={handleChange}
                  name={'fullname'}
                  value={formData.fullname}
          />
          </div>

          <div className='col-md-6'>
                <Input title={'Email'} 
                    placeholder={'Your email address'} 
                    handleChange={handleChange}
                    type='email'
                    name={'email'}
                    value={formData.email}

                />
          </div>
       </section>
       <section className='row' >
          <div className='col-md-6'>
          <Input title={'Password'} 
                  placeholder={'This is the members password'} 
                  handleChange={handleChange}
                  name={'password'}
                  type="password"
                  value={formData.password}
          />
          </div>

          <div className='col-md-6'>
                <Input title={'Confirm Password'} 
                    placeholder={'Your password'} 
                    handleChange={handleChange}
                    type='password'
                    name={'confirm_password'}
                    value={formData.confirm_password}

                />
          </div>
       </section>
       <section className='row' >
       <div className='col-md-6' style={{"display":"flex","height":"40px","marginTop":"20px"}}>
       <Select title="User Role" 
               dataKeys={["super_admin","guest"]}
               dataValues={["super admin","guest"]}
               handleChange={handleChange}
               name='role'
               selected={formData.role}
         />   
        </div>
            
        <div className='col-md-6' style={{"display":"flex","height":"40px","marginTop":"20px"}}>
        <Select title="Status" 
               dataKeys={["active","inactive"]}
               dataValues={["active","inactive"]}
               handleChange={handleChange}
               name='status'
               selected={formData.status}
         />   
        </div>
        </section>

        <div className='nav'>
               <button type="button" class="btn btn-dark" onClick={handleSubmit}>Submit</button>
           </div>
        </form>
}
        </div>
        </div>
        </section>
      </Layout>
  )
}

export default Index